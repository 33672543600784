
import { ref, computed } from "vue";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const year: any = ref({
      id: new Date().getFullYear() + "-" + (new Date().getFullYear() + 1),
      name: new Date().getFullYear() + "-" + (new Date().getFullYear() + 1),
    });
    const yearList: any = ref([]);
    for (let i = new Date().getFullYear(); i >= 2009; i--) {
      yearList.value.push({
        name: i + "-" + (i + 1),
        id: i + "-" + (i + 1),
      });
    }

    const onChange = (args) => {
      emit("changeYear", args);
    };
    return { close, year, yearList, onChange };
  },
};
